import { getPostingDetailAPI, getPostingAmenListAPI, getPostingCmtListAPI } from "./api/posting";

export const usePostingStore = defineStore("posting", () => {
  const state = reactive({
    detail: {},
    amenList: [],
  });

  const modalState = reactive({
    open: false,
    list: [],
    groupedCmt: [],
    cmtList: [],
  });

  async function getPostingDetailActor(payload) {
    try {
      const { data: res = {} } = await getPostingDetailAPI(payload);
      if (!res?.result) throw new Error("포스팅 조회에 실패했습니다.");
      state.detail = res.result;
      return state.detail;
    } catch (err) {
      console.error(`[ERROR][posting.getPostingDetailActor] ${err}`);
    }
  }

  async function getPostingAmenListActor(payload) {
    try {
      const { data: res = {} } = await getPostingAmenListAPI(payload);
      if (!res?.result) throw new Error("포스팅 아멘 목록 조회에 실패했습니다.");
      console.log(`getPostingAmenListActor  :::`, res.result);
      return res.result;
    } catch (err) {
      console.error(`getPostingAmenListActor ${err}`);
    }
  }

  async function getPostingCmtListActor({ postingCd, refresh = false }) {
    try {
      if (refresh) {
        modalState.groupedCmt = [];
        modalState.cmtList = [];
      }

      const { data: res = {} } = await getPostingCmtListAPI({ postingCd });
      if (!res?.result) throw new Error("포스팅 댓글 목록 조회에 실패했습니다.");
      modalState.cmtList = [...res.result];
      modalState.groupedCmt = getCmtPaging(modalState.cmtList, 10);
      return true;
    } catch (err) {
      console.error(`getPostingCmtListActor ${err}`);
    }
  }

  function getCmtPaging(array, perGroup) {
    const result = {};
    for (let i = 0, max = array.length; i < max; i++) {
      const groupIndex = Math.floor(i / perGroup);
      if (!result[groupIndex]) {
        result[groupIndex] = [];
      }
      result[groupIndex].push(array[i]);
    }
    return result;
  }

  return {
    state,
    modalState,
    getPostingDetailActor,
    getPostingAmenListActor,
    getPostingCmtListActor,
  };
});
