import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  let baseURL = base || "/api";
  /* if (["localhost", "dev.jwj.kr"].includes(hostname)) {
      baseURL = base || "/api";
    } else {
      baseURL = `https://jwj.kr${base || "/api"}`;
    } */

  return axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * [049-01] 포스팅 상세 / 임시저장 포스팅 조회 API
 * @param {string} postingCd : 포스팅 코드
 * @returns {Promise} result
 */
export const getPostingDetailAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting-view.do", { params: { ...payload } });
};

/**
 * [049-02] 포스팅 (임시)저장 API
 * @param {string} grpCd : 교회/단체 코드(필수)
 * @param {string} postingCnts : 포스팅 내용
 * @param {string} postingCntsCut : 포스팅 내용 요약
 * @param {string} regLDate : 로컬작성일시(필수)
 * @desc postingCd 발급
 */
export const savePostingAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting.do", objToFormData(payload));
};

/**
 * [049-03] 포스팅 수정 API
 * @param {string} postingCd : 포스팅 코드
 * @param {string} grpCd : 교회/단체 코드(필수)
 * @param {string} postingCnts : 포스팅 내용
 * @param {string} postingCntsCut : 포스팅 내용 요약
 * @param {string} regLDate : 로컬작성일시(필수)
 * @param {string} tempSaveYn : 임시저장여부
 * @param {string} uploadFileCd : 업로드한 이미지 코드(콤마로 구분, 최대20개)
 */
export const updatePostingAPI = async (payload) => {
  return await axiosInstance("/ajax").put("/grp/posting.do", objToFormData(payload));
};

/**
 * [049-04] 포스팅 삭제 API
 * @param {string} postingCd : 포스팅 코드
 */
export const deletePostingAPI = async (payload) => {
  return await axiosInstance("/ajax").delete("/grp/posting.do", objToFormData(payload));
};

/**
 * [049-05] 포스팅 댓글 목록 API
 * @param {string} postingCd : 포스팅 코드
 * @returns {Promise} result : object
 */
export const getPostingCmtListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting/comment.do", { params: { ...payload } });
};

/**
 * [049-09] 포스팅 아멘 목록 API
 * @param {string} postingCd : 포스팅 코드
 * @method GET
 */
export const getPostingAmenListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/grp/posting/amen.do", { params: { ...payload } });
};

/**
 * [049-10] 포스팅 아멘 저장 API
 * @param {string} postingCd : 포스팅 코드
 * @returns {Promise} result : Y/N
 */
export const setPostingAmenAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/grp/posting/amen.do", objToFormData(payload));
};
